import styled from 'styled-components';

import { ButtonProps } from './types';

export const ButtonBase = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 0.3s;
  &:focus,
  &:hover {
    outline: none;
  }
`;

export const Button = styled(ButtonBase)<ButtonProps>`
  width: 100%;
  height: 2.8rem;
  color: #FAFAFA;
  background-color: #DE383B;
  border: 1px solid;
  border-radius: 10px;
  transition: color 0.3s, background-color 0.5s;
  &:not(:disabled):hover,

`;

export const IconButton = styled(ButtonBase)`
  padding: 0;
`;
