import React from 'react';

import { IDetailsProps } from '../props';
import { InfoBox, InfoLabel, InfoData } from '../styles';
import Code from '../../../dtos/Code';

interface IProps extends Code {
  companyId: number;
  value: string;
  dynamic: string;
  observation: string;
  isBlocked: string;
  volume: string;
  partition: string;
  stackingLimit: string;
  warning: string;
  product?: string;
  onuNumber?: string;
  usefulInformation?: string;
}

const ImbelData: React.FC<IDetailsProps<IProps>> = ({ data }) => (
  <>
    <InfoBox hidden={!data?.product}>
      <InfoLabel>Produto:</InfoLabel>
      <InfoData>
        <p>{data?.product}</p>
      </InfoData>
    </InfoBox>
    <InfoBox>
      <InfoLabel>Status:</InfoLabel>
      <InfoData>{data?.isBlocked}</InfoData>
    </InfoBox>
    <InfoBox hidden={!data?.observation}>
      <InfoLabel>Observação:</InfoLabel>
      <InfoData>{data?.observation}</InfoData>
    </InfoBox>
    <InfoBox hidden={!data?.volume}>
      <InfoLabel>Volume: </InfoLabel>
      <InfoData>{data?.volume}</InfoData>
    </InfoBox>
    <InfoBox hidden={!data?.partition}>
      <InfoLabel>Partição:</InfoLabel>
      <InfoData>{data?.partition}</InfoData>
    </InfoBox>
    <InfoBox hidden={!data?.stackingLimit}>
      <InfoLabel>Limite de empilhamento:</InfoLabel>
      <InfoData>{data?.stackingLimit}</InfoData>
    </InfoBox>
    <InfoBox hidden={!data?.onuNumber}>
      <InfoLabel>Número ONU:</InfoLabel>
      <InfoData>{data?.onuNumber}</InfoData>
    </InfoBox>
    <InfoBox hidden={!data?.warning}>
      <InfoLabel>Aviso de perigo:</InfoLabel>
      <InfoData>{data?.warning}</InfoData>
    </InfoBox>
    <InfoBox hidden={!data?.usefulInformation}>
      <InfoLabel>Informações úteis:</InfoLabel>
      <InfoData>{data?.usefulInformation}</InfoData>
    </InfoBox>
  </>
);

export default ImbelData;
