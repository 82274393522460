import api from '../services/api';
import Code from '../dtos/Code';

class CodesRepository {
  async find(code: string): Promise<Code> {
    const { data } = await api.get(`/codes/${code}`);
    return data;
  }
}

export default new CodesRepository();
