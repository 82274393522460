import React, { forwardRef, InputHTMLAttributes } from 'react';
import { v4 as uuid } from 'uuid';

import { Container, Field, Label } from './styles';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  id?: string;
  error?: boolean;
  toClipboard?: boolean;
}
const Input: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { label, id: identify, error, ...rest },
  ref
) => {
  const id = identify || uuid();

  return (
    <Container htmlFor={id}>
      {label && <Label>{label}</Label>}
      <Field id={id} {...rest} ref={ref} error={error} placeholder=" " />
    </Container>
  );
};

export default forwardRef(Input);
