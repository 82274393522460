import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import {
  Body,
  ButtonContainer,
  ProductDescription,
  ProductDetailsContainer,
  ProductInfoContainer,
  ProductName,
  QuestionScroll,
  StatsContainer,
} from './styles';
import { FieldContainer, Text, Input, Button } from '../../components';
import Code from '../../dtos/Code';
import { Code as CodeContainer, CodeBox } from '../../pages/Code/styles';
import { Select } from '../Select';
import { CreateQuestions } from '../../lib/answer';
import answerRepository from '../../lib/answer';
import interactionRepository from '../../lib/interaction';
import ResourceDTO from '../../dtos/Resource';
import getPosition from '../../services/getPosition';

interface ProductByCodeProps {
  qrCode: Code;
  loadingResources: boolean;
  resource: ResourceDTO | undefined;
}
const ProductByCode: React.FC<ProductByCodeProps> = ({
  qrCode,
  loadingResources,
  resource,
}) => {
  const [isSubmited, setIsSubmited] = useState(false);
  const [forum, setForum] = useState<CreateQuestions[]>([]);
  const [latLng, setLatLng] = useState<{ lat: number; lng: number }>();
  useEffect(() => {
    const getLatLng = async () => {
      const position = await getPosition();
      const havePosition = position?.latitude && position?.longitude;
      if (!havePosition) {
        toast.error('É necessário permitir o acesso a sua localização.');
      } else {
        setLatLng({
          lat: position?.latitude,
          lng: position?.longitude,
        });
      }
    };
    getLatLng();
  }, []);
  const changeValue = (questionId: string, value: string): void => {
    setForum((prev) => {
      if (prev.length) {
        const existingQuestion = prev.find(
          (prevQuestion) => prevQuestion.questionId === questionId
        );
        if (existingQuestion) {
          const updatedQuestion = {
            ...existingQuestion,
            answerText: value,
          };

          const updatedForum = prev.map((prevQuestion) => {
            if (prevQuestion.questionId === questionId) {
              return updatedQuestion;
            } else {
              return prevQuestion;
            }
          });
          return updatedForum;
        } else {
          const newQuestion = {
            questionId: questionId,
            answerText: value,
            code: qrCode.value,
            status: 'Envasado',
          };
          const updatedForum = [...prev, newQuestion];

          return updatedForum;
        }
      } else {
        return [
          {
            questionId: questionId,
            answerText: value,
            code: qrCode.value,
            status: 'Envasado',
          },
        ];
      }
    });
  };
  const inputValue = (
    questionId: string,
    type: 'select' | 'input'
  ): string | { value: string; label: string } => {
    if (!forum.length) return '';
    const found = forum.find((answers) => {
      return answers.questionId === questionId;
    });
    if (type === 'input') {
      return found?.answerText || '';
    } else if (type === 'select' && found) {
      return {
        value: found.questionId,
        label: found.answerText,
      };
    } else {
      return '';
    }
  };
  const submit = async () => {
    if (!forum) {
      toast.error('missing info');
    } else {
      await answerRepository.createQuestions(forum).then(async () => {
        if (resource?.id && latLng) {
          await interactionRepository.saveInteraction({
            resourceId: resource?.id,
            code: qrCode.value,
            type: 'interact',
            latitude: latLng.lat,
            longitude: latLng.lng,
            status: 'Envasado',
          });
        }
        setForum([]);
        toast.success('message sent');
      });
      setIsSubmited(true);
    }
  };
  return (
    <>
      {loadingResources ? (
        <Loading />
      ) : (
        <Body>
          <ProductDetailsContainer>
            <CodeBox>
              <CodeContainer>{qrCode.value}</CodeContainer>
            </CodeBox>
            <ProductInfoContainer>
              <ProductName>{resource?.name}</ProductName>
              <ProductDescription>{resource?.description}</ProductDescription>
              <StatsContainer>
                {resource?.status ? <p>Status: {resource.status}</p> : null}
                {resource?.statusDate ? (
                  <p>
                    Data do Status:
                    {format(new Date(resource.statusDate), 'dd/MM/yyyy HH:mm')}
                  </p>
                ) : null}
                {resource?.fields.length
                  ? resource.fields.map((item, key) => {
                      return (
                        <p key={key}>
                          {item.resourceField.name}: {item.value}
                        </p>
                      );
                    })
                  : null}
              </StatsContainer>
            </ProductInfoContainer>
          </ProductDetailsContainer>
          <ProductDetailsContainer>
            {resource?.questions?.length && !isSubmited ? (
              <>
                <QuestionScroll>
                  {resource?.questions?.length
                    ? resource.questions.map((question, idx) => {
                        if (question.options.length) {
                          return (
                            <FieldContainer key={question.id}>
                              <Text>{question.questionText}</Text>
                              <Select
                                name={question.id}
                                id={question.id}
                                options={question.options.map((item) => ({
                                  value: item.id,
                                  label: item.optionText,
                                }))}
                                value={
                                  inputValue(question.id, 'select') as string
                                }
                                onChange={(value: any) => {
                                  changeValue(question.id, value.label);
                                }}
                                placeholder={''}
                                noOptionsMessage={() =>
                                  'Nenhum resource encontrado.'
                                }
                                autoFocus
                              />
                            </FieldContainer>
                          );
                        } else {
                          return (
                            <FieldContainer key={question.id}>
                              <Text>{question.questionText}</Text>
                              <Input
                                type="text"
                                name="options"
                                value={
                                  inputValue(question.id, 'input') as string
                                }
                                onChange={(e) => {
                                  changeValue(question.id, e.target.value);
                                }}
                              />
                            </FieldContainer>
                          );
                        }
                      })
                    : null}
                </QuestionScroll>

                <ButtonContainer>
                  <div style={{ maxWidth: '100px' }}>
                    <Button
                      type="submit"
                      onClick={submit}
                      // disabled={!havePermission || loading}
                    >
                      Enviar
                    </Button>
                  </div>
                </ButtonContainer>
              </>
            ) : isSubmited ? (
              <h4
                style={{
                  textAlign: 'center',
                }}
              >
                Mensagem enviada
              </h4>
            ) : (
              <h4>{resource?.canInteractMessage}</h4>
            )}
          </ProductDetailsContainer>
        </Body>
      )}
    </>
  );
};

export default ProductByCode;
