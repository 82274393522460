import React from 'react';

import { IDetailsProps } from '../props';
import { InfoBox, InfoLabel, InfoData } from '../styles';
import Code from '../../../dtos/Code';

export interface SaasProps extends Code {
  fields: {
    displayName: string;
    value: string;
  }[];
};

const SaasData: React.FC<IDetailsProps<SaasProps>> = ({ data }) => {
  console.log('SaasData props:', data);

  // Verifique se os campos estão presentes antes de tentar mapeá-los
  if (!data.fields || data.fields.length === 0) {
    console.log('Nenhum campo para renderizar.');
    return <div>Nenhum dado disponível para mostrar.</div>;
  }

  return (
    <>
      {data.fields.map(({ displayName, value }) => (
        <InfoBox key={displayName}>
          <InfoLabel>{displayName}:</InfoLabel>
          <InfoData>{value}</InfoData>
        </InfoBox>
      ))}
    </>
  );
};

export default SaasData;
