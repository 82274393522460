import ResourceDTO from '../dtos/Resource';
import api from '../services/api';

class ResourceRepository {
  async find(resourceId: string, code: string): Promise<ResourceDTO> {
    const { data } = await api.get(`/open/resources/${resourceId}/${code}`);
    return data;
  }
}

export default new ResourceRepository();
