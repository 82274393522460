import React from 'react';

import { IDetailsProps } from './props';
import Code from '../../dtos/Code';

import Imbel from './Imbel';
import Saas, { SaasProps } from './Saas';

// Função de utilidade para checar se o objeto é do tipo SaasProps
function isSaasProps(object: any): object is SaasProps {
  return 'fields' in object && 'value' in object && 'resourceId' in object;
}

interface DetailsProps<T = Code | SaasProps> {
  code: T;
}

const Details: React.FC<DetailsProps> = ({ code }) => {
  console.log('Detalhes do código recebido:', code);

  // Definindo o componente a ser renderizado
  let ComponentToRender: React.FC<IDetailsProps>;
  let componentProps: SaasProps | Code | {}; // O tipo de props que será passado para o componente

  if (isSaasProps(code)) {
    // Se code for do tipo SaasProps, renderize Saas
    ComponentToRender = Saas;
    componentProps = code; // code já é SaasProps aqui
    console.log('Renderizando Saas com:', code);
  } else {
    // Se não for SaasProps, então tratamos como Code ou renderizamos NoData
    ComponentToRender = Imbel; // ou outro componente correspondente ao Code
    componentProps = code; // code é tratado como Code aqui
    console.log('Renderizando Imbel ou NoData com:', code);
  }

  // Assegurando que componentProps tenha o tipo correto
  return <ComponentToRender data={componentProps as any} />;
}

export default Details;
