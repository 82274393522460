import api from '../services/api';
import Code from '../dtos/Code';

class InteractionRepository {
  async saveInteraction({
    resourceId,
    code,
    type,
    longitude,
    latitude,
    status,
  }: {
    resourceId: string;
    code: string;
    status?: string;
    type?: 'view' | 'interact';
    latitude: number;
    longitude: number;
  }): Promise<Code> {
    const { data } = await api.post(`/interactions`, {
      resourceId,
      code,
      longitude,
      latitude,
      ...(type ? { interactionType: type } : {}),
      ...(status ? { status } : {}),
    });
    return data;
  }
}

export default new InteractionRepository();
