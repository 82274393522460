import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Body,
  ButtonContainer,
  CnpjPTag,
  CompanyDescription,
  CompanyNameTitle,
  Container,
  Header,
  Logo,
  ProductDetailsContainer,
} from './styles';
import { format } from 'date-fns';
import companyRepository from '../../lib/company';
import { Button, FieldContainer, Input, Text } from '../../components';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { CompanyMarketDTO } from '../../dtos/CompanyDTO';
interface Params {
  value?: string;
}

export default function Company() {
  const { value } = useParams<Params>();

  const [forum, setForum] = useState({
    advertiserName: '',
    advertiserWebsite: '',
  });
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [didRespond, setDidRespond] = useState(false);
  const [company, setCompany] = useState<CompanyMarketDTO>();
  const findCompany = useCallback(
    async (code: string) => {
      try {
        if (!value) {
          return;
        }
        const data = await companyRepository.find(code);
        setCompany(data);
        return data;
      } catch (err) {
        toast.error(err);
      } finally {
        setLoadingCompany(false);
      }
    },
    [value]
  );
  useEffect(() => {
    if (value) {
      const loadCode = async () => {
        await findCompany(value);
      };
      loadCode();
    }
  }, [value, findCompany]);
  const submit = async () => {
    if (!value) {
      toast.error('nem um valor foi informado');
      return;
    }
    await companyRepository
      .answerQuestionsCompany({ ...forum, licensedQrCodeId: value })
      .then((res) => {
        toast.success('message sent');
        setDidRespond(true);
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally();
  };
  return (
    <Container>
      {loadingCompany ? (
        <Loading />
      ) : (
        <>
          <Header>
            <Logo />
            <span>
              Informações exibidas em: {format(new Date(), 'dd/MM/yyyy HH:mm')}
            </span>
          </Header>
          <Body>
            <ProductDetailsContainer>
              <CompanyNameTitle>{company?.company?.name}</CompanyNameTitle>
              <CnpjPTag>{company?.company?.document}</CnpjPTag>
              <CompanyDescription>{company?.observation}</CompanyDescription>
            </ProductDetailsContainer>
            <ProductDetailsContainer>
              {didRespond ? (
                <h4
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Mensagem enviada
                </h4>
              ) : (
                <>
                  <CompanyDescription>
                    Caso as informações ou o local (site) de venda do anunciante
                    não sejam esses, informe aqui o nome do anunciante e o local
                    (site) onde você leu esse QRCode.
                  </CompanyDescription>
                  <FieldContainer>
                    <Text>Nome do anunciante</Text>
                    <Input
                      type="text"
                      name="options"
                      value={forum.advertiserName}
                      onChange={(e) => {
                        setForum((prev) => ({
                          ...prev,
                          advertiserName: e.target.value,
                        }));
                      }}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Text>Local (site) onde está o anúncio</Text>
                    <Input
                      type="text"
                      name="options"
                      value={forum.advertiserWebsite}
                      onChange={(e) => {
                        setForum((prev) => ({
                          ...prev,
                          advertiserWebsite: e.target.value,
                        }));
                      }}
                    />
                  </FieldContainer>
                  <ButtonContainer>
                    <div style={{ maxWidth: '100px' }}>
                      <Button type="submit" onClick={submit}>
                        Enviar
                      </Button>
                    </div>
                  </ButtonContainer>
                </>
              )}
            </ProductDetailsContainer>
          </Body>
        </>
      )}
    </Container>
  );
}
