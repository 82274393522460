import api from '../services/api';
import { CompanyMarketDTO } from '../dtos/CompanyDTO';

export interface RespondCompany {
  advertiserName: string;
  advertiserWebsite: string;
  licensedQrCodeId: string;
}
class CompanyRepository {
  async find(code: string): Promise<CompanyMarketDTO> {
    const { data } = await api.get(`licensed-qrcodes/${code}`);
    return data;
  }
  async answerQuestionsCompany(
    respondCompany: RespondCompany
  ): Promise<CompanyMarketDTO> {
    const { data } = await api.post(
      `/answer-licensed-questions`,
      respondCompany
    );
    return data;
  }
}

export default new CompanyRepository();
