const theme = {
  colors: {
    primary: '#215253',
    primaryLight1: '#275B5C',
    primaryLight2: '#3B6A6B',
    primaryLight3: '#49797A',
    primaryLight4: '#568E8F',
    primaryDark1: '#1A4242',
    primaryDark2: '#154142',
    primaryDark3: '#0F3233',
    primaryDark4: '#053233',
    grayScale: {
      base: '#222324',
      variation1: '#303233',
      variation2: '#414345',
      variation3: '#5E6163',
      variation4: '#8A8D91',
      variation5: '#B6BABF',
      variation6: '#CED2D9',
      variation7: '#DADFE5',
      variation8: '#E9EEF5',
      variation9: '#F0F3F7',
      variation10: '#F5F8FC',
    },
    title: '#212121',
    text: '#424242',
    background: '#FCFCFC',
    page: '#F0F3F7',
    error: '#e74c3c',
    border: '#e8e8e8',
  },
  fonts: {
    title: "'Roboto', sans-serif",
    text: "'Roboto', sans-serif",
  },
  sizes: {
    title: '28px',
    subTitle: '18px',
    text: '14px',
    header: '52px',
    sideBar: '230px',
    borderRadius: '4px',
  },
};

export default theme;
