import styled from 'styled-components';

import logo from '../../assets/images/logo-loading.svg';

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  background: url(${logo}) center center no-repeat;
  background-size: 150px 150px;
  background-color: transparent;
  border-radius: ${(props) => props.theme.sizes.borderRadius};
`;
