import React, { createContext, useCallback, useState, useContext } from 'react';
import { toast } from 'react-toastify';

import getPosition from '../services/getPosition';
import codesRepository from '../lib/codes';
import Code from '../dtos/Code';

interface CodeContextData {
  code: Code;
  loading: boolean;
  find(code: string): Promise<void>;
}

const CodeContext = createContext<CodeContextData>({} as CodeContextData);

export const CodeProvider: React.FC = ({ children }) => {
  const [code, setCode] = useState<Code>({} as Code);
  const [loading, setLoading] = useState(false);

  const find = useCallback(async (value: string) => {
    setLoading(true);
    const position = await getPosition();
    const havePosition = position?.latitude && position?.longitude;
    if (!havePosition) {
      toast.error('É necessário permitir o acesso a sua localização.');
      setLoading(false);
      return;
    }

    try {
      const data = await codesRepository.find(value);
      setCode(data);
    } catch (err) {
      toast.error('Este código não existe');
      setCode({} as Code);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <CodeContext.Provider value={{ code, loading, find }}>
      {children}
    </CodeContext.Provider>
  );
};

export function useCode(): CodeContextData {
  const context = useContext(CodeContext);
  return context;
}
