import styled from 'styled-components';
import ReactSelect, { Theme } from 'react-select';

export type SelectOption<T = string> = {
  label: string;
  value: T;
};

export const Select = styled(ReactSelect).attrs(({ theme }) => ({
  theme: (selectTheme: Theme) => ({
    ...selectTheme,
    colors: {
      primary: '#DADFE5',
      primary25: '#F7F7F7',
    },
  }),
  styles: {
    control: (base: {}) => ({
      ...base,
      minHeight: '3rem',
      padding: '0 20px',
      marginBottom: '10px',
      fontSize: '1rem',
      borderWidth: '1px',
      borderRadius: theme.sizes.borderRadius,
      borderColor: '#DADFE5',
      boxShadow: 'unset',
    }),
    valueContainer: (base: {}) => ({
      ...base,
      padding: 0,
      color: '#222324',
    }),
    menu: (base: {}) => ({
      ...base,
      padding: '15px 12px',
      borderWidth: '1px',
      borderRadius: '10px',
      borderColor: '#DADFE5',
      backgroundColor: '#F7F7F7',
      zIndex: 99999,
    }),
    menuList: (base: {}) => ({
      ...base,
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridAutoRows: 'min-content',
      rowGap: '10px',
    }),
    option: (base: {}, props: { isSelected: boolean; isFocused: boolean }) => ({
      ...base,
      backgroundColor:
        props.isSelected || props.isFocused ? '#E5383B30' : 'transparent',
      borderRadius: '10px',
      color: '#E5383B',
      fontWeight: props.isSelected || props.isFocused ? 'bold' : 'normal',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: '#E5383B30',
      },
    }),
  },
}))``;
