import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: ${(props) => props.theme.fonts.text};
    -webkit-font-smoothing: antialised;
    -moz-osx-font-smoothin: grayscale;
  }

  html, body {
    min-height: 100%;
    background-color: ${(props) => props.theme.colors.grayScale.variation10};
  }

  body, input, button {
    font: 14px 'Roboto', sans-serif;
  }

  button {
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  input:focus {
    outline: none;
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    opacity: 0;
    transition: all 0.3s;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .Toastify__toast--success {
    background-color: ${(props) => props.theme.colors.primary};
  }

  .Toastify__toast {
    border-radius: 4px
  }
`;
