import React from 'react';
import { BrowserRouter, Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from './styles/GlobalStyle';
import themes from './styles/themes';

import history from './services/history';

import Routes from './routes/index';

import AppProvider from './hooks';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={themes}>
      <Router history={history}>
        <BrowserRouter>
          <AppProvider>
            <Routes />
          </AppProvider>

          <ToastContainer />
          <GlobalStyle />
        </BrowserRouter>
      </Router>
    </ThemeProvider>
  );
};

export default App;
