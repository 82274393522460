import React from "react";

import { CodeProvider } from "./codes";

const AppProvider: React.FC = ({ children }) => {
  return (
    <CodeProvider>{children}</CodeProvider>
  );
};

export default AppProvider;