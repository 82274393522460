import styled from 'styled-components';

export const Body = styled.div`
  padding: 120px 2rem 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 100px);
  max-width: 800px;
`;

export const TimeText = styled.span`
  font-size: 12px;
  font-weight: bold;
`;

export const ProductDetailsContainer = styled.div`
  margin-top: 10px;
  margin-left: 50px;
  row-gap: 2rem;
  height: max-content;
  display: grid;
  align-content: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.background};
  border: 2.5px solid ${(props) => props.theme.colors.border};
  border-radius: ${(props) => props.theme.sizes.borderRadius};
  min-width: 600px;
  width: 100%;
  @media (max-width: 650px) {
    min-width: auto;
  }
`;
export const QuestionScroll = styled.div``;
export const ProductInfoContainer = styled.div``;

export const ProductName = styled.p`
  font-weight: bold;
  margin-bottom: 20px;
`;
export const ProductDescription = styled.p`
  margin-bottom: 20px;
`;
export const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
