interface Position {
  readonly latitude: number;
  readonly longitude: number;
}

export default function getPosition(): Promise<Position> {
  return new Promise((resolve) =>
    navigator.geolocation.getCurrentPosition(({ coords }) => resolve(coords))
  );
}
