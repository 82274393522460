import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Code from '../pages/Code';
import Company from '../pages/Company';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Code} />
      <Route path="/:value" exact component={Code} />
      <Route path="/company/:value" exact component={Company} />
    </Switch>
  );
};

export default Routes;
