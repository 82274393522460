import api from '../services/api';
import Code from '../dtos/Code';

export interface CreateQuestions {
  questionId: string;
  answerText: string;
  code: string;
  status?: string;
}
class AnswerRepository {
  async createQuestions(answersList: CreateQuestions[]): Promise<Code> {
    const { data } = await api.post(`/answer-many`, { answers: answersList });
    return data;
  }
}

export default new AnswerRepository();
