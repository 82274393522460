import styled from 'styled-components';
import { Link as link } from 'react-router-dom';

type TextProps = {
  bold?: boolean;
};
type LinkProps = {
  align?: string;
};

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.title};
  font-size: ${(props) => props.theme.sizes.title};
  color: ${(props) => props.theme.colors.title};
`;

export const SubTitle = styled.h2`
  font-family: ${(props) => props.theme.fonts.title};
  font-size: ${(props) => props.theme.sizes.subTitle};
  color: ${(props) => props.theme.colors.title};
`;

export const Text = styled.p<TextProps>`
  font-family: ${(props) => props.theme.fonts.text};
  font-size: ${(props) => props.theme.sizes.text};
  color: ${(props) => props.theme.colors.text};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`;

export const Link = styled(link)<LinkProps>`
  color: ${(props) => props.theme.colors.primary};
  text-align: ${(props) => props.align || 'left'};
  text-decoration: none;
`;
