import React, { InputHTMLAttributes, KeyboardEvent } from 'react';
import { MdSearch } from 'react-icons/md';

import { SearchContainer, SearchInput, SearchIcon } from './styles';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  onSubmit: (e: React.FormEvent) => void;
  clearSearch: () => void;
}


const Search: React.FC<IProps> = ({ onSubmit, clearSearch, disabled, ...props }) => {
  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Escape') clearSearch();
  };

  return (
    <SearchContainer>
      <SearchInput onKeyUp={handleKeyUp} {...props} disabled={disabled} />
      <SearchIcon onClick={onSubmit} disabled={disabled}>
        <MdSearch />
      </SearchIcon>
    </SearchContainer>
  );
}

export default Search;
