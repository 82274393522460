import styled from 'styled-components';
import LogoImage from '../../assets/images/logo.svg';

type MainContainer = {
  imageBuffer?: Buffer;
};

export const Container = styled.main<MainContainer>`
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  justify-items: center;
  background-color: ${(props) => props.theme.colors.grayScale.variation10};
  background-image: ${(props) =>
    props.imageBuffer ? `url(${props.imageBuffer})` : 'none'};
  background-repeat: repeat;
`;
export const BodyContainer = styled.div<MainContainer>`
  background-image: ${(props) =>
    props.imageBuffer ? `url(${props.imageBuffer})` : 'none'};
  background-repeat: repeat;
`;

export const Header = styled.header`
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: grid;
  width: 100%;
  min-height: min-content;
  grid-template-rows: 1fr;
  grid-template-columns: auto auto;
  column-gap: 12rem;
  align-items: center;
  padding: 0.4rem 0.8rem;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  z-index: 999;

  > div {
    justify-self: end;
  }
`;

export const Logo = styled.img.attrs({
  src: LogoImage,
})`
  width: 160px;
`;
export const Body = styled.div`
  padding: 120px 2rem 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 100px);
  max-width: 800px;
`;
export const ProductDetailsContainer = styled.div`
  margin-top: 10px;
  margin-left: 50px;
  row-gap: 2rem;
  height: max-content;
  display: grid;
  align-content: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.background};
  border: 2.5px solid ${(props) => props.theme.colors.border};
  border-radius: ${(props) => props.theme.sizes.borderRadius};
  min-width: 600px;
  width: 100%;
  @media (max-width: 650px) {
    min-width: auto;
  }
`;

export const CompanyNameTitle = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 20px;
`;

export const CnpjPTag = styled.p`
  font-size: 16px;
`;
export const CompanyDescription = styled.p`
  font-size: 16px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
