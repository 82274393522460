import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useCode } from '../../hooks/codes';
import resourcesRepository from '../../lib/resource';
import interactionRepository from '../../lib/interaction';
import Loading from '../../components/Loading';
import Details from '../../components/Details';
import { Container, Header, Logo, HeaderSearch } from './styles';
import ProductByCode from '../../components/ProductByCode';
import ResourceDTO from '../../dtos/Resource';
import { toast } from 'react-toastify';
import getPosition from '../../services/getPosition';

interface Params {
  value?: string;
}

const CodeInfo: React.FC = () => {
  const [search, setSearch] = useState('');
  const { code, loading, find } = useCode();
  const { value } = useParams<Params>();
  const history = useHistory();
  const [latLng, setLatLng] = useState<{ lat: number; lng: number }>();
  const [resource, setResource] = useState<ResourceDTO>();
  const [loadingResources, setLoadingResources] = useState(false);

  const logAndToastError = (error: unknown) => {
    const errorMessage = error instanceof Error ? error.message : String(error);
    console.error('Erro ao buscar recursos:', errorMessage);
    toast.error(errorMessage);
  };

  const findResources = useCallback(async (id: string) => {
    setLoadingResources(true);
    console.log('Iniciando busca de recursos para ID:', id);
    try {
      const data = await resourcesRepository.find(id, value || '');
      if (!data) throw new Error(`Não foram encontrados recursos para o ID: ${id}`);
      console.log('Recursos encontrados:', data);
      setResource(data);
    } catch (error) {
      logAndToastError(error);
    } finally {
      setLoadingResources(false);
    }
  }, [value]);

  useEffect(() => {
    const getLatLng = async () => {
      console.log('Buscando posição atual...');
      const position = await getPosition();
      const havePosition = position?.latitude && position?.longitude;
      if (!havePosition) {
        toast.error('É necessário permitir o acesso a sua localização.');
      } else {
        setLatLng({
          lat: position?.latitude,
          lng: position?.longitude,
        });
        console.log('Posição obtida:', position);
      }
    };
    getLatLng();
  }, []);

  useEffect(() => {
    if (resource?.id && latLng) {
      console.log('Salvando interação de visualização...');
      const saveInteractionView = async () => {
        await interactionRepository.saveInteraction({
          resourceId: resource?.id,
          code: code.value,
          latitude: latLng.lat,
          longitude: latLng.lng,
          status: 'Envasado',
        });
      };
      saveInteractionView();
    }
  }, [code, latLng, resource]);

  useEffect(() => {
    if (code?.resourceId) {
      console.log('Carregando código de recurso...');
      const loadCode = async () => {
        await findResources(code.resourceId);
      };
      loadCode();
    }
  }, [findResources, code]);

  useEffect(() => {
    if (value) {
      console.log(`Valor do código alterado para: ${value}`);
      find(value);
    }
  }, [value, find]);

  const handleClearSearch = () => {
    console.log('Limpando pesquisa...');
    setSearch('');
  };

  const handleSearch = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    console.log('Buscando código:', search);
    if (!search) return;
    history.push(`/${search}`);
    find(search);
  };

  return (
    <Container
      imageBuffer={resource?.image ? Buffer.from(resource.image.data) : undefined}
    >
      <Header>
        <Logo />
        <HeaderSearch
          placeholder="Pesquisar código"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onSubmit={handleSearch}
          clearSearch={handleClearSearch}
          disabled={loading}
        />
      </Header>
      {loading && <Loading />}
      {!loading && code.resourceId ? (
        <ProductByCode
          qrCode={code}
          resource={resource}
          loadingResources={loadingResources}
        />
      ) : (
        <Details code={code} />
      )}
    </Container>
  );
};

export default CodeInfo;
